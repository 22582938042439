import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));

const Section = React.lazy(() => import("../Layout2/Section"));
const About = React.lazy(() => import("../../component/About"));
const Services = React.lazy(() => import("../../component/Services"));
const Features = React.lazy(() => import("../../component/Features"));
const Pricing = React.lazy(() => import("../../component/Pricing"));
const Clients = React.lazy(() => import("../../component/Clients"));
const Blog = React.lazy(() => import("../../component/Blog"));
const Contact = React.lazy(() => import("../../component/Contact"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));

// import { Spinner } from "reactstrap";

function Layout2() {
  const { t } = useTranslation();
  const [state, setState] = useState({
    navItems: [
      { id: 2, idnm: "home", navheading: t("about") },
      { id: 3, idnm: "services", navheading: t("services") },
      { id: 7, idnm: "contact", navheading: t("contact") },
    ],
    pos: document.documentElement.scrollTop,
    imglight: true,
    navClass: "nav-sticky navbar-custom sticky sticky-dark",
  });

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
    return () => window.removeEventListener("scroll", scrollNavigation, true);
  }, []);

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > state.pos) {
      setState({
        ...state,
        navClass: "nav-sticky navbar-custom sticky sticky-dark",
        imglight: false,
      });
    } else {
      setState({
        ...state,
        navClass: "nav-sticky navbar-custom sticky sticky-dark",
        imglight: true,
      });
    }
  }

  //set preloader div
  function PreLoader() {
    return (
      <div id="preloader">
        <div id="status">
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Suspense fallback={PreLoader()}>
        <Navbar
          navItems={state.navItems}
          navClass={state.navClass}
          imglight={state.imglight}
        />
        <Section />
        <Services />
        <Contact />
        <Footer />
      </Suspense>
    </React.Fragment>
  );
}
export default Layout2;
