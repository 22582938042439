import React, { useState } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";

function LnaguageDropdown() {
  const [state, setState] = useState({
    dropdownOpen: false,
    activeLng: localStorage.getItem("language"),
  });

  function toggleLang() {
    setState({
      ...state,
      dropdownOpen: !state.dropdownOpen,
      activeLng: localStorage.getItem("language"),
    });
  }
  const handleLangSelect = (lng) => () => {
    localStorage.setItem("language", lng);
    window.location.reload(false);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={state.dropdownOpen} toggle={toggleLang}>
        <DropdownToggle caret>
          {state.activeLng === "en" ? "English" : "Portuguese"}
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem onClick={handleLangSelect("en")}>English</DropdownItem>
          <DropdownItem onClick={handleLangSelect("pt")}>
            Portuguese
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}
// }
export default LnaguageDropdown;
