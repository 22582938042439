import React, { useState } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from "reactstrap";
import logolight from "../../assets/images/fulllogo_nobuffer.png";

//import icon
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";
import LnaguageDropdown from "../LanguageDropdown";

function CustomNavbar(props) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    isOpenMenu: false,
  });

  function toggle() {
    setState({ isOpenMenu: !state.isOpenMenu });
  }
  const navItems = [
    { id: 2, idnm: "home", navheading: t("about") },
    { id: 3, idnm: "services", navheading: t("services") },
    { id: 7, idnm: "contact", navheading: t("contact") },
  ];
  var targetId = navItems.map((item) => {
    return item.idnm;
  });

  return (
    <React.Fragment>
      {/* Navbar Start */}
      <nav
        className={
          "navbar navbar-expand-lg fixed-top sticky nav-sticky navbar-custom sticky sticky-dark"
        }
      >
        <Container>
          {/* LOGO */}
          <NavbarBrand className="logo" href="/">
            <img src={logolight} alt="logo" height="65" />
          </NavbarBrand>
          <NavbarToggler onClick={toggle}>
            <i>
              <FeatherIcon icon="menu" />
            </i>
          </NavbarToggler>

          <Collapse
            id="navbarCollapse"
            isOpen={state.isOpenMenu}
            className=" navbar-collapse"
          >
            <Nav navbar className="ms-auto navbar-center" id="mySidenav">
              {navItems.map((item, key) => (
                <NavItem
                  key={key}
                  className={item.navheading === "Home" ? "active" : ""}
                >
                  <NavLink
                    style={{ fontSize: "16px" }}
                    className={item.navheading === "Home" ? "active" : ""}
                    href={`/`}
                  >
                    {item.navheading}
                  </NavLink>
                </NavItem>
              ))}
              <LnaguageDropdown />
            </Nav>
          </Collapse>
        </Container>
      </nav>
    </React.Fragment>
  );
}
// }
export default CustomNavbar;
